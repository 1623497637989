<template>
  <v-row align="center" justify="center" class="pa-4">
    <v-col>
      <h1 style="font-weight: 800; color: #e53935">แก้ไขกลุ่มสินค้า</h1>
    </v-col>
    <v-card class="pa-8" width="100%">
      <v-form ref="createForm" v-model="checkValidate" :lazy-validation="lazy">
        <v-row>
          <v-col cols="12" md="3" sm="3" class="pt-8"
            ><span class="pt-4">สถานะ</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="6" sm="6">
            <v-switch v-model="activeFlag" label="ใช้งาน"></v-switch>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="12" md="3" sm="3" class="pt-0"
            ><span>เลือกหมวดหมู่ของกลุ่มสินค้า</span>
            <span style="color: red">*</span>
          </v-col>
          <v-col cols="12" md="9" sm="9">
            <v-autocomplete
              v-model="category"
              :items="itemsCategory"
              item-text="category"
              item-value="id"
              placeholder="เลือกหมวดหมู่ของกลุ่มสินค้า"
              solo
              dense
              :rules="rules.required"
            ></v-autocomplete
          ></v-col>
          <!-- <v-col cols="12" md="3" sm="3" class="pt-0"
            ><span>เลือกประเภทของสินค้า</span>
            <span style="color: red">*</span>
          </v-col>
          <v-col cols="12" md="9" sm="9">
            <v-autocomplete
              v-model="type"
              :items="itemsType"
              item-text="type"
              item-value="id"
              placeholder="เลือกประเภทสินค้า"
              solo
              dense
              :rules="rules.required"
            ></v-autocomplete>
          </v-col> -->
          <v-col cols="12" md="3" sm="3" class="pt-0"
            ><span>เลือกยี่ห้อของกลุ่มสินค้า</span>
            <span style="color: red">*</span>
          </v-col>
          <v-col cols="12" md="9" sm="9">
            <v-autocomplete
              v-model="brand"
              :items="itemsBrand"
              item-text="brandName"
              item-value="id"
              placeholder="เลือกยี่ห้อของกลุ่มสินค้า"
              solo
              dense
              :rules="rules.required"
            ></v-autocomplete>
          </v-col>
          <!-- <v-col cols="12" md="3" sm="3" class="pt-0"
            ><span>รหัสสินค้า</span>
            <span style="color: red">*</span>
          </v-col>
          <v-col cols="12" md="9" sm="9">
            <v-text-field
              v-model="sku"
              placeholder="รหัสสินค้า"
              solo
              dense
              :rules="rules.required"
            ></v-text-field>
          </v-col> -->
          <v-col cols="12" md="3" sm="3" class="pt-0"
            ><span>ชื่อกลุ่มสินค้า</span>
            <span style="color: red">*</span>
          </v-col>
          <v-col cols="12" md="9" sm="9">
            <v-text-field
              v-model="groupName"
              placeholder="ชื่อกลุ่มสินค้า"
              solo
              dense
              :rules="rules.required"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3" sm="3" class="pt-0"
            ><span>รายละเอียดแบบย่อ</span>
            <!-- <span style="color: red">*</span> -->
          </v-col>
          <v-col cols="12" md="9" sm="9">
            <v-textarea
              v-model="shortDesc"
              placeholder="รายละเอียด"
              solo
              dense
              :rules="rules.required"
            ></v-textarea>
          </v-col>
          <v-col cols="12" md="3" sm="3" class="pt-0"
            ><span>Subscribe หรือไม่</span>
          </v-col>
          <v-col cols="12" md="3" sm="3">
            <v-switch v-model="subscribable"></v-switch>
          </v-col>
          <v-col cols="12" md="3" sm="3">
            <v-text-field
              class="mt-4"
              v-if="subscribable"
              v-model="subscribeMaxMonth"
              suffix="เดือน"
              solo
              dense
              :rules="rules.required"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3" sm="3">
            <v-text-field
              class="mt-4"
              v-if="subscribable"
              v-model="subscribePrice"
              suffix="บาท"
              solo
              dense
              :rules="rules.required"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3" sm="3" class="pt-0"
            ><span>แสดงที่สินค้าโปรโมชั่น หรือไม่</span>
          </v-col>
          <v-col cols="12" md="9" sm="3">
            <v-switch v-model="isPromotion"></v-switch>
          </v-col>
          <v-col cols="12" md="3" sm="3" class="pt-0"
            ><span>แสดงที่สินค้าใหม่ หรือไม่</span>
          </v-col>
          <v-col cols="12" md="9" sm="3">
            <v-switch v-model="isNew"></v-switch>
          </v-col>
          <v-col cols="12" md="12" sm="6" xs="12">
            <v-card outlined>
              <v-row no-gutters align="center" justify="center">
                <v-col cols="11" class="mt-5 ma-10" id="step-3">
                  <span>รูปสินค้าหลัก</span>
                  <span style="color: red">*</span>
                  <v-row align="center" justify="center">
                    <v-img
                      v-model="picture_1"
                      @click="changePic1()"
                      src="@/assets/upload.png"
                      class="mr-3"
                      max-width="50"
                    >
                    </v-img>
                    <span>เลือกรูปภาพที่นี่</span>
                  </v-row>
                  <v-col
                    cols="3"
                    md="3"
                    class="pl-5 pr-5 row fill-height align-center sortable-list"
                  >
                    <v-card dense light class="pa-1" v-if="showImage">
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-icon small light @click="RemoveImageMain()"
                          >mdi-close</v-icon
                        >
                      </v-card-actions>

                      <v-img
                        :src="showImage"
                        width="200px"
                        aspect-ratio="1.8"
                        contain
                      ></v-img>
                    </v-card>
                  </v-col>
                  <input
                    type="file"
                    ref="image"
                    id="picTure1"
                    accept="image/jpg"
                    @change="showPicture1($event)"
                    style="display: none"
                    hidden
                /></v-col>
                <v-btn
                  class="mb-4"
                  color="primary"
                  dense
                  @click="submitfilemain1()"
                >
                  อัพโหลดรูปภาพ</v-btn
                >
              </v-row>
            </v-card>
          </v-col>

          <v-col cols="12" class="mt-5">
            <v-card outlined>
              <v-row no-gutters align="center">
                <v-col cols="11" class="mt-5 ma-10" id="step-3">
                  <span>รูปภาพสินค้าเพิ่มเติม</span>
                </v-col>
                <v-col cols="12" class="mb-5"><v-divider></v-divider></v-col>
                <v-col
                  cols="12"
                  md="12"
                  class="pl-3 mb-5"
                  @click="onPickFile()"
                >
                  <v-row no-gutters align="center" justify="center">
                    <v-file-input
                      v-model="DataImage"
                      :items="DataImage"
                      accept="image/png"
                      @change="UploadImage()"
                      id="file_input"
                      multiple
                      :clearable="false"
                      style="display: none"
                    ></v-file-input>
                    <v-img
                      max-width="50"
                      src="@/assets/upload.png"
                      class="mr-3"
                    ></v-img>
                    <span>เลือกรูปภาพที่นี่</span>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                  v-if="product_image.length !== 0"
                  class="mb-5"
                >
                  <draggable
                    v-model="product_image"
                    :move="onMove"
                    @start="drag = true"
                    @end="drag = false"
                    class="pl-5 pr-5 row fill-height align-center sortable-list"
                  >
                    <v-col
                      v-for="(item, index) in product_image"
                      :key="index"
                      cols="3"
                      md="3"
                    >
                      <v-card dense light class="pa-1">
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-icon small light @click="RemoveImage(index, item)"
                            >mdi-close</v-icon
                          >
                        </v-card-actions>
                        <!-- <img width="100%"  :src="item.url" v-if="MethodProduct === 'Create'" />
                              <img width="100%" :src="`${PathImage}${item.url}`" v-else /> -->
                        <v-img
                          :src="item.url"
                          aspect-ratio="1.8"
                          contain
                        ></v-img>
                        <v-card-text class="text-md-center">
                          <!-- <span class="subheading">{{item.name|truncate(20, '...') }}</span> -->
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </draggable>
                </v-col>
              </v-row>
              <v-row align="center" justify="center"
                ><v-btn
                  class="mb-10"
                  color="primary"
                  dense
                  @click="submitfilemain2()"
                >
                  อัพโหลดรูปภาพ</v-btn
                ></v-row
              >
            </v-card>
          </v-col>
          <v-col cols="12" md="12" sm="12" xs="12">
            <h3 style="font-weight: 800; color: #e53935">เพิ่มสินค้า</h3>
            <v-sheet outlined color="red" rounded="">
              <v-card outlined class="px-6 py-2">
                <v-row
                  v-for="(n, index) in products"
                  :key="index"
                  align="center"
                  class="py-0 my-0 pb-4 mb-1"
                  style="border: 1px solid rgb(0 0 0); border-radius: 5px"
                >
                  <v-col cols="12" md="6" sm="12"
                    ><span>รหัสสินค้า</span>
                    <span style="color: red">*</span>
                    <v-autocomplete
                      v-model="products[index].sku"
                      :items="itemsSKU"
                      item-text="itemNo"
                      item-value="itemNo"
                      placeholder="SKU"
                      solo
                      dense
                      :rules="rules.required"
                      @change="getData(index, products[index].sku)"
                    >
                      <template v-slot:selection="{ item }">
                        {{ item.itemNo }} - {{ item.description }}
                      </template>
                      <template v-slot:item="{ item }">
                        {{ item.itemNo }} - {{ item.description }}
                      </template>
                    </v-autocomplete>
                    <!-- <v-text-field
                      v-model="products[index].sku"
                      placeholder="SKU"
                      solo
                      dense
                      :rules="rules.required"
                    ></v-text-field> -->
                  </v-col>
                  <v-col cols="12" md="6" sm="12"
                    ><span>ชื่อสินค้า</span>
                    <span style="color: red">*</span>
                    <v-text-field
                      v-model="products[index].productName"
                      placeholder="ชื่อสินค้า"
                      solo
                      dense
                      :rules="rules.required"
                    ></v-text-field>
                  </v-col>
                  <!-- <v-col cols="12" md="4" sm="12"
                    ><span>รส</span>
                    <span style="color: red">*</span>
                    <v-text-field
                      v-model="products[index].flavor"
                      placeholder="รส"
                      solo
                      dense
                      :rules="rules.required"
                    ></v-text-field>
                  </v-col> -->
                  <v-col cols="12" md="12" sm="12"
                    ><span>รายละเอียด</span>
                  </v-col>
                  <v-col cols="12" md="12" sm="12">
                    <quill-editor
                      :content="products[index].description"
                      :options="editorOption"
                      @change="onEditorChange($event)"
                    />
                  </v-col>
                  <v-col cols="12" md="4" sm="12"
                    ><span>จำนวน</span>
                    <span style="color: red">*</span>
                    <v-text-field
                      v-model="products[index].stockAmt"
                      placeholder="จำนวน"
                      solo
                      dense
                      :rules="rules.required"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" sm="12"
                    ><span>หน่วย</span>
                    <span style="color: red">*</span>
                    <v-text-field
                      v-model="products[index].unit"
                      placeholder="(ถุง,กระสอบ)"
                      solo
                      dense
                      :rules="rules.required"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" sm="12"
                    ><span>น้ำหนัก (สำหรับคำนวนค่าขนส่ง)</span>
                    <span style="color: red">*</span>
                    <v-text-field
                      v-model="products[index].weight"
                      placeholder="น้ำหนัก"
                      solo
                      dense
                      :rules="rules.required"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" sm="12"
                    ><span>ราคาปกติ</span>
                    <span style="color: red">*</span>
                    <v-text-field
                      v-model="products[index].price"
                      placeholder="ราคาปกติ"
                      solo
                      dense
                      :rules="rules.required"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" sm="12"
                    ><span>ราคาเมื่อลดราคา</span>
                    <span style="color: red">*</span>
                    <v-text-field
                      v-model="products[index].discountPrice"
                      placeholder="ราคาเมื่อลดราคา"
                      solo
                      dense
                      :rules="rules.required"
                    ></v-text-field>
                  </v-col>
                  <v-row>
                    <v-col cols="12" md="12" sm="12" class="text-left pt-5"
                      ><span class="pt-2">ระยะเวลาเริ่มต้นของส่วนลด</span>
                      <span style="color: red">*</span></v-col
                    >
                    <v-col cols="12" md="12" sm="12" class="text-left pt-5">
                      <v-row align="center" justify="center">
                        <v-col cols="6">
                          <v-menu
                            v-model="products[index].menufrom"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                >วันที่เริ่ม<span style="color: red"
                                  >*</span
                                ></span
                              >
                              <v-text-field
                                v-model="products[index].discountFrom"
                                label="วันที่เริ่ม"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                dense
                                solo
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="products[index].discountFrom"
                              @input="products[index].menufrom = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="6">
                          <v-menu
                            v-model="products[index].menuto"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                >วันที่สิ้นสุด<span style="color: red"
                                  >*</span
                                ></span
                              >
                              <v-text-field
                                v-model="products[index].discountTo"
                                label="วันที่สิ้นสุด"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                dense
                                solo
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="products[index].discountTo"
                              @input="products[index].menuto = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-col cols="12">
                    <v-card outlined>
                      <v-row no-gutters align="center">
                        <v-col cols="11" class="mt-5 ma-10" id="step-3">
                          <span>รูปภาพสินค้าเพิ่มเติม</span>
                        </v-col>
                        <v-col cols="12" class="mb-5"
                          ><v-divider></v-divider
                        ></v-col>
                        <v-col
                          cols="12"
                          md="12"
                          class="pl-3 mb-5"
                          @click="onPickFile2()"
                        >
                          <v-row no-gutters align="center" justify="center">
                            <v-file-input
                              v-model="products[index].DataImage"
                              :items="products[index].DataImage"
                              accept="image/png"
                              @change="UploadImage2(index)"
                              id="file_input2"
                              multiple
                              :clearable="false"
                              style="display: none"
                            ></v-file-input>
                            <v-img
                              max-width="50"
                              src="@/assets/upload.png"
                              class="mr-3"
                            ></v-img>
                            <span>เลือกรูปภาพที่นี่</span>
                          </v-row>
                        </v-col>
                        <v-col cols="12" md="12" class="mb-5">
                          <draggable
                            v-model="products[index].product_image"
                            :move="onMove2"
                            @start="drag = true"
                            @end="drag = false"
                            class="
                              pl-5
                              pr-5
                              row
                              fill-height
                              align-center
                              sortable-list
                            "
                          >
                            <v-col
                              v-for="(item, index) in products[index]
                                .product_image"
                              :key="index"
                              cols="3"
                              md="3"
                            >
                              <v-card dense light class="pa-1">
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-icon
                                    small
                                    light
                                    @click="RemoveImage2(index, item)"
                                    >mdi-close</v-icon
                                  >
                                </v-card-actions>

                                <!-- :src="products[index].item.url2" -->
                                <v-img
                                  :src="item.url2"
                                  aspect-ratio="1.8"
                                  contain
                                ></v-img>
                                <v-card-text class="text-md-center">
                                </v-card-text>
                              </v-card>
                            </v-col>
                          </draggable>
                        </v-col>
                      </v-row>
                      <v-row align="center" justify="center"
                        ><v-btn
                          class="mb-10"
                          color="primary"
                          dense
                          @click="submitfileDes(index)"
                        >
                          อัพโหลดรูปภาพ</v-btn
                        ></v-row
                      >
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="12" class="my-2">
                    <v-row align="center" justify="end">
                      <v-btn
                        v-if="products.length > 0"
                        @click="deleteproducts(products[index], index)"
                        dense
                        color="red"
                        class="mr-6"
                        dark
                        rounded
                      >
                        <v-icon>mdi-minus</v-icon>
                      </v-btn>
                      <!-- <v-btn
                        @click="addproducts()"
                        dense
                        color="green"
                        dark
                        rounded
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn> -->
                    </v-row>
                  </v-col>
                </v-row>
                <v-row align="right" justify="end">
                  <v-col cols="12" style="text-align: right">
                    <v-btn
                      @click="addproducts()"
                      dense
                      color="green"
                      dark
                      rounded
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-sheet>
          </v-col>

          <v-row class="ma-5" align="center" justify="end">
            <v-btn @click="cancel()" class="mr-4">กลับ</v-btn>
            <v-btn @click="submit()" color="primary">บันทึก</v-btn>
          </v-row>
        </v-row>
      </v-form>
    </v-card>
  </v-row>
</template>
<script>
import draggable from "vuedraggable";
import { Decode } from "@/services";
import Datepicker from "vuejs-datepicker";

export default {
  components: {
    draggable,
    Datepicker,
  },
  data() {
    return {
      date: new Date(2016, 9, 16),
      id: "",
      category: "",
      itemsCategory: [],
      type: "",
      itemsType: [],
      brand: "",
      itemsBrand: [],
      company: "",
      itemsCompany: [],
      sku: "",
      itemsSKU: [],

      groupName: "",
      shortDesc: "",
      subscribable: false,
      subscribeMaxMonth: "",
      subscribePrice: "",
      isPromotion: false,
      isNew: false,
      termDesc: "",
      description: "<br/><br/><br/><br/><br/><br/>",
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
            [
              "link",
              // "image",
              // "video"
            ],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },

      productName: "",
      flavor: "",
      stockAmt: "",
      discountFrom: new Date().toISOString().substr(0, 10),
      discountTo: new Date().toISOString().substr(0, 10),
      // stockAmt: "",
      unit: "",
      price: "",
      img: [],
      DataImage: [],
      product_image: [],
      pic1: "",
      pic2: [],
      picture_1: "",
      showImage: "",
      imageName1: "",
      pic1URL: "",
      activeFlag: true,
      discountPrice: null,
      weight: null,
      setupFee: null,
      isHilight: false,
      showIndex: null,
      companyName: [],
      firstDate: new Date().toISOString().slice(0, 10),

      // listDescription: [],
      productPriceList: [],
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      checkValidate: true,
      lazy: false,
      products: [],
      menufrom: false,
      menuto: false,
      //
    };
  },
  methods: {
    async getData(index, val) {
      console.log("indexval", index, val);
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/products/getAllProductERP?itemNo=` + val
      );
      this.products[index].productName = response.data.data[0].description;
      this.products[index].description = response.data.data[0].description2;
      this.products[index].unit = response.data.data[0].unitOfMeasure;
      this.products[index].price = response.data.data[0].priceTag;
      console.log("getAllproductsllll", this.itemsSKU);
    },
    // addData(rowIndex) {
    //   this.products[rowIndex].name =
    //     this.products[index].sku.description;
    // },
    async getAllProduct() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/products/getAllProductERP?isMap=0`
      );
      this.itemsSKU = response.data.data;
      console.log("getAllproductsllll", this.itemsSKU);
      for (let i in this.itemsSKU) {
        this.itemsSKU[i].count = parseInt(i) + 1;
        //console.log(this.list);
      }
    },
    onEditorChange({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.description = html;
    },
    // onEditorChangetermDesc({ quill, html, text }) {
    //   console.log("editor change!", quill, html, text);
    //   this.termDesc = html;
    // },
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageName1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    onPickFile2() {
      document.getElementById("file_input2").click();
    },
    UploadImage() {
      for (let i = 0; i < this.DataImage.length; i++) {
        const element = this.DataImage[i];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic2 = reader.result;
          var url = URL.createObjectURL(element);
          this.product_image.push({
            image_data: this.pic2,
            url: url,
            name: this.DataImage[i].name,
            id: "-1",
          });
        };
      }
    },
    UploadImage2(index) {
      // this.products[index].product_image = [];
      for (let i = 0; i < this.products[index].DataImage.length; i++) {
        const element = this.products[index].DataImage[i];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.products[index].pic2 = reader.result;
          var url2 = URL.createObjectURL(element);
          this.products[index].product_image.push({
            // image_data: this.products[index].pic2,
            url2: url2,
            name: this.products[index].DataImage[i].name,
            id: "-1",
          });
          // console.log('this.products[index].product_image ',this.products[index] );
        };
      }
    },
    onMove2({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext;
      const draggedElement = draggedContext;
      return (
        (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      );
    },
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext;
      const draggedElement = draggedContext;
      return (
        (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      );
    },
    RemoveImageMain() {
      this.picture_1 = "";
      this.showImage = "";
    },
    RemoveImage(index, item) {
      console.log(index, item);
      if (item.id !== "-1") {
        this.Remove_img.push({
          id: item.id,
        });
      }
      this.product_image.splice(index, 1);
    },
    RemoveImage2(index, item) {
      console.log(index, item);
      if (item.id !== "-1") {
        this.Remove_img.push({
          id: item.id,
        });
      }
      this.products[index].product_image.splice(index, 1);
    },

    cancel() {
      this.$router.push("ManageProduct");
    },
    async submitfilemain1() {
      this.loading = true;
      // let formData = new FormData();
      // formData.append("files", this.pic1);
      // formData.append("type", this.typeImg);
      // console.log("formData", formData);
      // const auth = {
      //   headers: {
      //     "Content-Type": "multipart/form-data",
      //   },
      // };
      const fileData = {
        file: this.pic1,
      };
      this.uploaded = true;
      const response = await this.axios.post(
        `${process.env.VUE_APP_API}/files/base64`,
        fileData
      );
      // .then(function () {
      console.log("SUCCESS!!");
      console.log("response", response);
      this.uploaded = false;
      if (response.data.response_status == "SUCCESS") {
        this.loading = false;
        await this.$swal.fire({
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          icon: "success",
          text: "อัพโหลดสำเร็จ",
        });
        this.pic1URL = response.data.data.path;
      } else {
        this.loading = false;
        await this.$swal.fire({
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          icon: "error",
          text: "อัพโหลดไฟล์ไม่สำเร็จ",
        });
      }
    },
    async submitfilemain2() {
      const fileData = {
        file: this.pic2,
      };
      this.uploaded = true;
      const response = await this.axios.post(
        `${process.env.VUE_APP_API}/files/base64`,
        fileData
      );
      // .then(function () {
      console.log("SUCCESS!!");
      console.log("response", response);
      this.uploaded = false;
      if (response.data.response_status == "SUCCESS") {
        this.loading = false;
        await this.$swal.fire({
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          icon: "success",
          text: "อัพโหลดสำเร็จ",
        });
        this.pic2URL = response.data.data.path;
      } else {
        this.loading = false;
        await this.$swal.fire({
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          icon: "error",
          text: "อัพโหลดไฟล์ไม่สำเร็จ",
        });
      }
    },
    async submitfileDes(index) {
      const fileData = {
        file: this.products[index].pic2,
      };
      this.uploaded = true;
      const response = await this.axios.post(
        `${process.env.VUE_APP_API}/files/base64`,
        fileData
      );
      // .then(function () {
      console.log("SUCCESS!!");
      console.log("response", response);
      this.uploaded = false;
      if (response.data.response_status == "SUCCESS") {
        this.loading = false;
        await this.$swal.fire({
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          icon: "success",
          text: "อัพโหลดสำเร็จ",
        });
        this.products[index].imgUrl = response.data.data.path;
      } else {
        this.loading = false;
        await this.$swal.fire({
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          icon: "error",
          text: "อัพโหลดไฟล์ไม่สำเร็จ",
        });
      }
    },
    async submit() {
      console.log("this.picture_1", this.picture_1);
      console.log("this.DataImage", this.DataImage);

      if (this.$refs.createForm.validate(true)) {
        var user = JSON.parse(Decode.decode(localStorage.getItem("userSPM")));
        const auth = {
          headers: { Authorization: `Bearer ${user.token}` },
        };
        const productGroup = {
          categoryId: this.category,
          typeId: this.type,
          brandId: this.brand,
          // sku: this.sku,
          groupName: this.groupName,
          // description: this.description,
          description: this.shortDesc,
          subscribable: this.subscribable,
          subscribeMaxMonth: this.subscribeMaxMonth,
          subscribePrice: this.subscribePrice,
          isPromotion: this.isPromotion,
          isNew: this.isNew,
          // termDesc: this.termDesc,
          // stockAmt: this.stockAmt,
          // unit: this.unit,
          // price: this.price,
          activeFlag: this.activeFlag,
          // discountPrice: this.discountPrice,
          // weight: this.weight,
          // setupFee: this.setupFee,
          // isHilight: this.isHilight,
          // showIndex: this.showIndex,
          imgUrl: this.pic1URL,
          groupImgs: this.pic2URL,
          // productPriceList: this.productPriceList,
          // products: this.products,
        };
        console.log("data", productGroup);

        var postData = {
          products: this.products,
          productGroup: productGroup,
        };
        const response = await this.axios.put(
          `${process.env.VUE_APP_API}/products/updateProductGroup/` + this.id,
          postData,
          auth
        );
        console.log("response product", response);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `เพิ่มสินค้าสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.push("ManageProduct");
        } else {
          this.$swal.fire({
            icon: "error",
            text: `เพิ่มสินค้าไม่สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
    async addproducts() {
      this.products.push({
        sku: this.sku,
        productName: this.name,
        flavor: this.flavor,
        description: this.description,
        stockAmt: this.stockAmt,
        unit: this.unit,
        weight: this.weight,
        price: this.price,
        discountPrice: this.discountPrice,
        product_image: [],
        discountFrom: new Date().toISOString().substr(0, 10),
        discountTo: new Date().toISOString().substr(0, 10),
        DataImage: [],
        pic2: [],
        imgUrl: [],
      });
    },
    async deleteproducts(val, index) {
      console.log("deleteproducts", val, index);
      this.products.splice(index, 1);
      if (val.id) {
        this.$swal({
          text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "ยกเลิก",
          confirmButtonText: "ต้องการลบข้อมูล",
        }).then(async (result) => {
          if (result.isConfirmed) {
            var user = JSON.parse(
              Decode.decode(localStorage.getItem("userSPM"))
            );
            const auth = {
              headers: { Authorization: `Bearer ${user.token}` },
            };
            const response = await this.axios.delete(
              `${process.env.VUE_APP_API}/products/` + val.id,
              auth
            );
            this.products.splice(index, 1);
            console.log(response);
            if (response.data.response_status == "ERROR") {
              this.$swal.fire({
                icon: "error",
                text: response.data.message,
                showConfirmButton: false,
                timer: 2000,
              });
            } else {
              this.$swal.fire({
                icon: "success",
                text: "ลบข้อมูลสำเร็จ",
                showConfirmButton: false,
                timer: 1000,
              });
              // await location.reload();
              this.getAllProduct();
            }
          }
        });
      }
    },
    async addRow1() {
      this.productPriceList.push({
        price: null,
        // description: this.listDescription,
        companyId: this.companyId,
      });
    },
    deleteRow1() {
      if (this.productPriceList.length > 0) {
        this.productPriceList.pop();
      }
    },
  },
  async created() {
    var productdata = JSON.parse(
      Decode.decode(localStorage.getItem("productdata"))
    );
    this.id = productdata.id;
    const response = await this.axios.get(
      `${process.env.VUE_APP_API}/products/findOneProductGroup?id=` + this.id
    );
    console.log("response.data.dataaaaaaaaaa", response.data.data);
    var Product = response.data.data;
    this.activeFlag = Product.activeFlag;
    this.category = Product.categoryId;
    this.brand = Product.brandId;
    this.groupName = Product.groupName;
    this.shortDesc = Product.description;
    this.subscribable = Product.subscribable;
    this.subscribeMaxMonth = Product.subscribeMaxMonth;
    this.subscribePrice = Product.subscribePrice;
    this.isPromotion = Product.isPromotion;
    this.isNew = Product.isNew;
    if (Product.products) {
      this.products = Product.products;
    }
    // for (const i in Product.products) {
    //   this.products[i].product_image[i].url2 = Product.products[i].imgUrl[i];

    // }
    // this.products.url2 = Product.products.imgUrl
    // console.log("this.products", this.products[]);

    this.showImage = Product.imgUrl;
    this.getAllProduct();
    // this.addRow1();
    // this.addproducts();
    const cateDropdown = await this.axios.get(
      `${process.env.VUE_APP_API}/categories`
    );
    const typeDropdown = await this.axios.get(
      `${process.env.VUE_APP_API}/types`
    );
    const brandDropdown = await this.axios.get(
      `${process.env.VUE_APP_API}/brands`
    );
    const companyDropdown = await this.axios.get(
      `${process.env.VUE_APP_API}/companies`
    );
    this.itemsCategory = cateDropdown.data.data;
    this.itemsType = typeDropdown.data.data;
    this.itemsBrand = brandDropdown.data.data;
    this.itemsCompany = companyDropdown.data.data;
    console.log("companyDropdown", this.itemsCompany);
  },
};
</script>
